@font-face {
    font-family: 'Inter';
    src: url(https://static.csp.nccgroup.com/fonts/Inter-ExtraLight.ttf) format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Inter';
    src: url(https://static.csp.nccgroup.com/fonts/Inter-Light.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Inter';
    src: url(https://static.csp.nccgroup.com/fonts/Inter-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Inter';
    src: url(https://static.csp.nccgroup.com/fonts/Inter-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Inter';
    src: url(https://static.csp.nccgroup.com/fonts/Inter-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Inter';
    src: url(https://static.csp.nccgroup.com/fonts/Inter-Bold.ttf) format('truetype');
    font-weight: 700;
}

html,
body {
    font-family: 'Inter', sans-serif;

    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}